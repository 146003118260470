/* ==========================================================================
   Atom - burger
   ========================================================================== */

.a-burger {
  padding: 0;
  max-height: 22px;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover,
  &:focus {
    padding: 0;
    background-color: transparent;
  }

  .a-burger__box {
    width: 24px;
    height: 22px;
    display: inline-block;
    position: relative;
  }

  .a-burger__inner {
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }

  .a-burger__inner,
  .a-burger__inner::before,
  .a-burger__inner::after {
    width: 24px;
    height: 4px;
    background-color: $dark-gray;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .a-burger__inner::before,
  .a-burger__inner::after {
    content: "";
    display: block;
  }

  .a-burger__inner::before {
    top: -8px;
  }

  .a-burger__inner::after {
    bottom: -8px;
  }

  .a-burger--squeeze .a-burger__inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .a-burger--squeeze .a-burger__inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }

  .a-burger--squeeze .a-burger__inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &.active .a-burger__inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &.active .a-burger__inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }

  &.active .a-burger__inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
