.o-secondary-menu {
  // background-color: $gray;
  background-color: lighten($gray, 9);
  display: none;

  @media (min-width: $medium) { display: block; }

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    padding: 4px $spacer;
    opacity: 0;

    &.active { opacity: 1; }

    li {
      // line-height: 1;

      a {
        position: relative;
        color: $black;
        font-size: $fs-14;
        text-decoration: none;
        padding: 0 0 0 8px;
        margin-left: 8px;

        &:hover { text-decoration: underline; }
      }

      &:nth-child(n+3) {
        a {
          &::before {
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            left: 0;
            width: 1px;
            background-color: $black;
          }
        }
      }

      &:last-child {
        &::after {
          content: url(../images/external.svg);
          width: 8px;
          margin-left: 2px;
          display: inline-block;
        }
      }

      .o-secondary-menu__search {
        // transition: opacity 0.2s ease-in-out;

        input[type="text"],
        input[type="submit"] {
          opacity: 0.8;
          // background: rgba($white, .6);
        }

        &:active,
        &:focus,
        &:hover {
          input[type="text"],
          input[type="submit"] {
            opacity: 1;
          }
        }

        input[type="text"] {
          display: inline-block;
          width: calc(100% - 55px);
          margin: 0;
          min-height: 24px;
          font-size: $fs-14;
          line-height: 1;
          padding-top: 0;
          padding-bottom: 0;

          &:focus {
            opacity: 1;
            // background: $white;

            & + input[type="submit"] { opacity: 1; }
          }
        }
      }
    }
  }
}
