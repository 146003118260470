.m-list-card {

  .m-list-card__link {
    text-decoration: none;
    color: $dark-gray;
  }

  .m-list-card__wrap {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border: 1px solid lighten($gray, 10);
    min-height: 200px;

    @media (min-width:$small) {
      flex-direction: row;
    }
  }

  .m-list-card__imageWrapper {
      flex-basis: 100%;
      max-width: 100%;
      position: relative;
      line-height: 0;
      min-height: 200px;
      background: linear-gradient(to right, #ededed, #f1f1f1, #f6f6f6, #fafafa, #ffffff);
      background-size: cover;
      background-position: center;

      @media (min-width:$small) {
        min-height: auto;
        flex-basis: 25%;
        max-width: 25%;
      }

      .m-list-card__image {
        @include object-fit(cover, center);
        min-width: 100%;
        min-height: 100%;
      }
  }

  .m-list-card__contentWrapper {
    flex-basis: 100%;
    max-width: 100%;
    padding: 16px;

    @media (min-width:$small) {
      flex-basis: 75%;
      max-width: 75%;
      padding: 24px;
    }
  }

  .m-list-card__title {
    margin: 0 0 8px;
    color: $dark-gray;
    font-size: $fs-32;

    .m-list-card__link {
      & span::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 5px;
        width: 100%;
        // height: 2px;

        background-image: -webkit-gradient(linear,left top,left bottom,from($green),to($green));
        background-image: -o-linear-gradient($green,$green);
        background-image: linear-gradient($green,$green);
        background-size: 1px 0px;
        background-repeat: repeat-x;
        background-position: 0 86%;

        transition: background-size 100ms ease-in;

        padding-bottom: 2px;
        z-index: -500;

      }
      &:hover, &:focus {
        & span::after {
          background-size: 1px 2px;
        }
      }
    }

    svg {
      display: inline-block;
      width: 24px;
      height: 22px;
      fill: $green;
      pointer-events: none;
      transform: rotate(-90deg)translateY(0px);
      transition: transform 100ms ease-in;
    }

    &:hover svg { transform: rotate(-90deg)translateY(5px); }

    span {
      margin-left: -5px;
      position: relative;
    }
  }

  .m-list-card__content {
    margin: 0 0 24px;
    color: $dark-gray;
    font-size: $fs-16;

    &--inline {
      display: inline;
      border-bottom: 0px solid $green;
      transition: border-bottom 150ms ease-in;

      &:hover {
        // text-decoration: underline;
        border-bottom: 1px solid $green;
      }
    }

    p {
      margin: 0;
      color: $dark-gray;
        font-size: $fs-16;
    }
  }

  .m-list-card__tags {

    .a-btn {
      margin-right: 8px;
    }
  }
}
