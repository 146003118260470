/* ==========================================================================
   Utilities - Responsive iframe
   ========================================================================== */

.u-responsive-iframe-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16x9 Aspect Ratio */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
