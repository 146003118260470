.a-page-heading__wrapper {
  padding: 32px 0 0;

  @media (min-width: 1000px) {
    max-width: 1000px;
  }

  @media (min-width: $content-max-width) {
    padding: 24px 0 0;
    max-width: $content-max-width;
  }
}

.a-page-heading {
  font-size: $fs-30;
  margin: 0;
  color: $white;

  @media (min-width: $small) {
    font-size: $fs-44;
  }

  @media (min-width: $medium) {
    font-size: $fs-60;
  }

  span {
    display: inline-block;
    padding: 9px 14px 2px;
    background-color: $green;
    line-height: 1;
    margin: 0 0 8px;

    @media (min-width: $small) {
      padding: 12px 20px 1px;
    }

    @media (min-width: $medium) {
      padding: 18px 20px 4px;
    }
  }

  // Color modifiers
  &--dark-green { span { background-color: $dark-green; } }
  &--light-green { span { background-color: $light-green; } }
}


// Meta heading
.a-meta-heading {

  .a-meta-heading__title {
    display: inline-block;
    padding: 13px 14px 4px;
    margin: 32px 0;
    background-color: $green;
    color: $white;
    line-height: 1;
    font-size: $fs-40;

    & a { text-decoration: none; }
    & a:hover { text-decoration: underline; }
  }
}
