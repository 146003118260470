/* ==========================================================================
   Utilities - Visually hidden
   ========================================================================== */

/** Visually Hidden
 * Make an element visually hidden, but accessible to assistive technology
 * @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0 !important;

  /** Visually Hidden Focusable
   * Extends `Visually Hidden` to allow the element to be focusable when navigated
   * to via the keyboard.
   */

  &:active,
  &:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
  }
}
