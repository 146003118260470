/* ==========================================================================
   Color settings
   ========================================================================== */

$green: #3BA935;
$dark-green: #266b2f;
$light-green: #9CCA87;

$dark-gray: #353535;
$gray: #d2d2d2;
$light-gray: #ededed;
$black: #000;
$white: #FFF;

$green-light-bg: lighten($green, 54);
$dark-green-light-bg: lighten($dark-green, 42);
$light-green-light-bg: $green-light-bg;

$gradient-gray: linear-gradient(to bottom, #ededed, #f1f1f1, #f6f6f6, #fafafa, #ffffff);
