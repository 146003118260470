/* ==========================================================================
   Atoms - Tags
   ========================================================================== */

.a-tag {

  background: $green;
  color: $white;
  font-family: $font-secondary;
  font-size: $fs-20;
  letter-spacing: 0.5px;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 6px 12px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  transition: background 150ms ease-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% - 24px);
    height: 3px;
    background: $white;
  }

  &:hover { @include darkenOnHover($green) }

  &.a-tag--dark-green { background: $dark-green; &:hover { @include darkenOnHover($dark-green) } }
  &.a-tag--light-green { background: $light-green; &:hover { @include darkenOnHover($light-green) } }

  &.a-tag--small {
    font-family: $font-primary;
    font-size: $fs-12;
    padding: 2px 8px 4px;
    letter-spacing: 1.1px;

    &::after {
      height: 2px;
      bottom: 3px;
      width: calc(100% - 16px);
    }
  }

  &.a-tag--block {
    display: block;
  }

  &.a-tag--secondary {
    background: $white;
    color: $green;
    border: 2px solid $green;

    &::after {
      background: $green;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }
}
