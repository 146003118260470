/* ==========================================================================
   Utilities - Grid
   ========================================================================== */

.grid-col {
  // margin-top: $spacing;
  // margin-bottom: $spacing;

  flex-basis: 100%;
  max-width: 100%;

  @media (min-width: $small) {

    &-33 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &-25 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &-20 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &--align-right {
      order: 2;
    }
  }

  @media (min-width: $medium) {

    &-75 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &-66 {
      flex-basis: 66.6667%;
      max-width: 66.6667%;
    }

    &-50 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &-33 {
      flex-basis: 33.3334%;
      max-width: 33.3334%;
    }

    &-25 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &-20 {
      flex-basis: 20%;
      max-width: 20%;
    }

    &--align-right {
      order: 2;
    }
  }
}


.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--subtract {
    margin: 0 -16px;

    @media (min-width: $medium) {
      // margin: 0 -16px;
    }
  }

  /* Grid with spacing between columns */
  &--spacing {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 2%;
    padding-right: 0%;
    padding-bottom: 2%;
    padding-left: 0%;
    @media (min-width: $medium) {
      padding-top: 1%;
      padding-right: 2%;
      padding-bottom: 1%;
      padding-left: 2%;
    }

    .grid-col {
      margin-top: 2%;
      margin-bottom: 2%;

      @media (min-width: $medium) {
        margin-right: 4%;
        margin-left: 4%;
      }
    }

      @media (min-width: $medium) {

        .grid-col {
          margin-top: 1%;
          margin-right: 0;
          margin-bottom: 1%;
          margin-left: 0;

        &-66 {
          flex-basis: 66%;
          max-width: 66%;
        }

        &-50 {
          flex-basis: 48.85%;
          max-width: 48.85%;
        }

        &-33 {
          flex-basis: 32.18%;
          max-width: 32.18%;
        }

        &-25 {
          flex-basis: 23.85%;
          max-width: 23.85%;
        }
      }
    }
  }
}
