/* ==========================================================================
   Sections - Shared style for sections
   ========================================================================== */

 .m-section-container {
    max-width: $content-max-width;
    margin-right: auto;
    margin-left: auto;

    &--small {
      max-width: 1000px;
      margin: 0 auto;

      @media (min-width: $content-max-width) {
        margin-right: auto;
        margin-left: 0;
      }
    }

    &--full-width {

    }
}

.row .m-section__heading {
  margin: 0 0 0 16px;
}

.m-section__heading {
  font-size: $fs-24;
  padding: 8px 16px 0 0;
  margin: 0;
  color: $dark-gray;

  @media (min-width: $medium) {
    font-size: $fs-36;
  }

  &--underline {
    border-bottom: 3px solid $green;
    padding-bottom: 0;
  }

  &--no-spacing {
    padding: 0;
  }
}

.m-section__spacer {
  padding-top: 10px;
  padding-bottom: 10px;
}
