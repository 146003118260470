/* ==========================================================================
   Utilities - Container
   ========================================================================== */

.u-container {
  max-width: $content-max-width;
  margin-right: auto;
  margin-left: auto;
  // padding-right: 0;
  // padding-left: 0;

  // &--no-spacing {
  //   margin-right: auto;
  //   margin-left: auto;
  //   padding-right: 0;
  //   padding-left: 0;
  // }

  &--medium { max-width: 1024px; }

  &--small {
    max-width: 740px;

    @media (min-width: 640px) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.u-container-spacing {
  max-width: $content-max-width;
  padding: 24px 0;
  @media (min-width: $content-max-width) {
    margin-right: auto;
    margin-left: auto;
  }
}
