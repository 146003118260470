/* ==========================================================================
   Organisms - Article
   ========================================================================== */

.o-article {
  // max-width: 1000px;
  margin: 22px auto 0;

  &--no-margin-top {
    margin-top: 0 !important;
  }

  @media (min-width: $content-max-width) {
    margin: 32px auto 0 0;
  }

  .o-article__content {
    & p:first-child { margin-top: 0; }
  }

  .o-article__title {
    font-family: $font-secondary;
    color: $dark-gray;
    font-size: $fs-40;
    line-height: 1.1;
    margin-bottom: 16px;

    @media (min-width: $small) {
      font-size: $fs-52;
    }

    @media (min-width: $large) {
      font-size: $fs-64;
    }

    &--medlem { margin-top: 0; }

    &--personal { margin-top: 32px; }
  }

  .o-article__categories {
    // margin-bottom: 40px;
  }

  .o-article__image-wrapper {
    position: relative;
    line-height: 0;

    .o-article__byline--member {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba( $white, .8 );
      margin: 0;
      font-size: $fs-14;
      line-height: normal;
      padding: 8px;
    }
  }

  .o-article__image {
    margin-top: 40px;
    width: 100%;
    @include object-fit(cover, center);

    &.o-article__image--medlem,
    &.o-article__image--personal {
      margin-top: 0;
      min-height: 100%;
    }
  }

  .o-article__image_cropped {
    margin-top: 40px;
    width: 100%;
    max-height: 530px;
    @include object-fit(cover, center);

    &.o-article__image--medlem,
    &.o-article__image--personal {
      margin-top: 0;
      min-height: 100%;
    }
  }

  .o-article__byline {
    margin: 0;
    font-size: $fs-14;
    color: $dark-gray;
  }

  .o-article__content-wrapper {
    margin-top: 30px;

    @media (min-width: $small) {
      // margin-top: 40px;
    }
  }

  .o-article__preamble {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-size: $fs-22;
    color: $dark-gray;

    @media (min-width: $small) {
      font-size: $fs-26;
    }
  }

  //wp image alignment
  img.alignright {
    float: right;
    padding: 10px;
  }

  img.alignleft {
    float: left;
    padding: 10px;
  }

  img.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
  }

  .o-article__evenemang {
    &--title {
      font-size: $fs-22;
      font-weight: bold;
      margin: 0;
    }

    &--content {
      margin-top: 0;
    }

    &--button {
      padding-top: 20px;
    }
  }
}
