$showDebug: false;

div.o-quick-links {
  position: relative;
  display: block;
  width: 100%;
  background: $green-light-bg;

  // Compensate for wrapping container
  // margin: 0 -1%;
  // width: 102%;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;

  div.o-quick-links__wrapper {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0 auto;
    padding: 30px 0 30px 0;

    @if $showDebug {
      background: yellow;
    }

    @media (max-width: $small) {
      padding: 30px 20px 50px 20px;
    }

    @media (max-width: $medium) {
      max-width: 600px;
    }

    h1.o-quick-links__title {
      font-family: $font-secondary;
    }

    ul.o-quick-links__list {
      position: relative;
      display: block;
      margin: -10px 0 0 0;
      padding: 0 16px 0 16px;
      list-style-type: none;

      webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 0px;
      -moz-column-gap: 0px;
      column-gap: 0px;

      @media (max-width: $small) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
      }

      li.o-quick-links__item {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;

        a.o-quick-links__item-link {
          position: relative;
          display: inline-block;
          margin: 0;
          padding: 0;
          text-decoration: none;
          font-family: $font-secondary;
          font-size: $fs-15;
          color: $black;
          max-width: 80%;
          padding: 10px 0 10px 0;
          transition: transform 125ms ease-out;

          &:hover,
          &--active {
            transform: translate3d(4px, 0px, 0px);

            h3.o-quick-links__item-title {
              border-bottom: 3px solid $green;
            }
          }

          h3.o-quick-links__item-title {
            position: relative;
            display: inline;
            margin: 0;
            padding: 0;
            border-bottom: 3px solid transparent;
            transition: border-bottom 125ms ease-out
          }

          svg.o-quick-links__item-chevron {
            position: absolute;
            display: block;
            top: 10px;
            left: -20px;
            width: 18px;
            height: 18px;
            fill: $green;
            margin-top: 4px;
            pointer-events: none;
            transform: translate3d(0, 0, 0) rotate(-90deg);
          }
        }
      }
    }
  }

  &--dark-green {
    background: $dark-green-light-bg;

    div.o-quick-links__wrapper {
      ul.o-quick-links__list {
        li.o-quick-links__item {
          a.o-quick-links__item-link {
            &:hover,
            &--active {
              h3.o-quick-links__item-title {
                border-bottom: 3px solid $dark-green;
              }
            }

            svg.o-quick-links__item-chevron {
              fill: $dark-green;
            }
          }
        }
      }
    }
  }
  &--light-green {
    background: $light-green-light-bg;

    div.o-quick-links__wrapper {
      ul.o-quick-links__list {
        li.o-quick-links__item {
          a.o-quick-links__item-link {
            &:hover,
            &--active {
              h3.o-quick-links__item-title {
                border-bottom: 3px solid $light-green;
              }
            }

            svg.o-quick-links__item-chevron {
              fill: $light-green;
            }
          }
        }
      }
    }
  }
}
