/* ==========================================================================
   Breakpoint settings
   ========================================================================== */

$tiny: 320px !default;
$small: 640px !default;
$medium: 960px !default;
$large: 1040px !default;

$tiny-max: 319px !default;
$small-max: 639px !default;
$medium-max: 959px !default;
$large-max: 1040px !default;

$content-max-width: 1220px;
// $content-max-width: 1000px;

$spacer: 12px;
