.m-tease-member {
  position: relative;
  flex-basis: 50%;
  max-width: 50%;
  background: $white;
  border: 1px solid $light-gray;
  background-position: center;
  background-size: cover;

  @media (min-width: $small) {
    flex-basis: 25%;
    max-width: 25%;
  }

  @media (min-width: $medium) {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .m-tease-member__content-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;

    &::after {
      content: '';
      width: 1px;
      height: 0px;
      padding-top: 100%;
      float: left;
    }
  }

  .m-tease-member__logo {

  }

  .m-tease-member__name {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $dark-gray;
    color: $white;
    padding: 4px 8px;
    font-size: $fs-14;
  }
}
