.m-tease {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  border: 1px solid $light-gray;
  background: $white;
  margin-bottom: 24px;
  min-height: 200px;

  .m-tease__image-wrapper {
    position: relative;
    line-height: 0;
    background: linear-gradient(to right, #ededed, #f1f1f1, #f6f6f6, #fafafa, #ffffff);
    flex-basis: 100%;
    max-width: 100%;
    overflow: hidden;

    @media (min-width: $small) {
      max-height: initial;
      flex-basis: 25%;
      max-width: 25%;
    }
  }

  .m-tease__image {
    min-height: 170px;
    height: 100%;
    min-width: 100%;
    margin: 0;
    @include object-fit(cover, center);

    @media (min-width: $small) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .m-tease__content-wrapper {
    position: relative;
    padding: 24px 16px;
    margin: 0;
    flex-basis: 100%;
    max-width: 100%;

    @media (min-width: $small) {
      flex-basis: 75%;
      max-width: 75%;
    }

    & .m-tease__type {
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: $font-secondary;
      letter-spacing: 1px;
      background-color: $green;
      color: $white;
      padding: 4px 8px 2px;
      font-size: $fs-14;
    }

    & * { margin: 0; }
    & a { text-decoration: none; }

    :hover {
      .m-tease__title {
        transform: translate3d(4px, 0px, 0px);
        border-bottom: 3px solid $green;
      }
      .m-tease__summary {
        // color: $green;
      }
    }
  }

  .m-tease__title-wrapper {
    position: relative;

    .m-tease__chevron {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      fill: $green;
      margin-top: 4px;
      pointer-events: none;
      transform: translate3d(0, 0, 0) rotate(-90deg);
    }

    .m-tease__title {
      margin: 0 0 8px 20px;
      font-family: $font-secondary;
      color: $dark-gray;
      position: relative;
      display: inline-block;
      padding: 0;
      line-height: 0.9;
      font-family: $font-secondary;
      font-size: $fs-30;
      border-bottom: 3px solid transparent;
      transition: transform 125ms ease-out;
    }

    .m-tease__plain-title {
      margin: 0 0 8px 0;
      font-family: $font-secondary;
      color: $dark-gray;
      position: relative;
      display: inline-block;
      padding: 0;
      line-height: 0.9;
      font-family: $font-secondary;
      font-size: $fs-30;
      border-bottom: 3px solid transparent;
    }
  }

  .m-tease__summary {
    font-size: $fs-14;
    @media (min-width: $small) {
      font-size: $fs-16;
    }
  }

  .m-tease__categories {
    margin-top: 24px;
  }

  .m-tease__documents {
    margin-top: 24px;
  }

  .m-tease__document-title {
    font-size: $fs-12;
    // font-family: $font-secondary;
    color: $green;
    text-transform: uppercase;
  }

  .m-tease__document-link {
    position: relative;
    font-size: $fs-12;
    // font-family: $font-secondary;
    color: $dark-gray;
    display: inline-block;
    margin: 0 4px 4px 0;
    padding: 2px 4px 2px 20px;
    border: 1px solid $dark-gray;
    background: $white;
    transition: background 150ms ease-out, padding 125ms ease-out;

    &:before {
      content: url('../images/file.svg');
      position: absolute;
      top: 2px;
      left: 0px;
      width: 10px;
      height: 10px;
      // opacity: 0.4;
      opacity: 0.8;
      pointer-events: none;
      transform: scale(0.6);
    }

    &:hover { background: lighten($dark-gray, 60); }

    // &:not(:last-child):after {
    //   content: '|';
    //   margin-left: 8px;
    //   color: $green;
    // }
  }

  .m-tease__staff-info,
  .m-tease__event-info {
    margin-top: 8px;
    color: $dark-gray;
  }

  .m-tease__staff-info-item,
  .m-tease__event-info-item {
    font-size: $fs-16;
    font-weight: bold;
    margin-bottom: 2px;
  }

  // Color modifiers

  // &--evenemang {
  // .m-tease__title-wrapper .m-tease__chevron {
  //     fill: $light-purple;
  //   }
  //   .m-tease__content-wrapper {
  //     &:hover {
  //       .m-tease__title {
  //         border-bottom-color: $light-purple;
  //       }
  //     }
  //     .m-tease__type {
  //       background-color: $light-purple;
  //     }
  //   }
  // }
}

.m-tease--rss {
  min-height: auto;
  border: 0;
  margin-bottom: 12px;
}

.m-tease__rsscategories {
    margin-top: 0;
    margin-left: 20px;
    font-size: $fs-14;
  }

.m-tease__rsstitle {
  margin: 6px 0 8px 20px;
  font-family: $font-secondary;
  color: $dark-gray;
  position: relative;
  display: inline-block;
  padding: 0;
  line-height: 0.9;
  font-family: $font-secondary;
  font-size: $fs-20;
  border-bottom: 3px solid transparent;
  transition: transform 125ms ease-out;
}

.m-tease__rsscategory {
  margin-left: 8px;
}
