/* ==========================================================================
   Global forms (From Zurb Foundation)
   ========================================================================== */

// $form-border-radius: 25px;
$form-border-radius: 2px;

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], [type='range'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 35px;
  margin: 0 0 2rem;
  padding: 2px 10px;
  border: 1px solid $dark-gray;
  border-radius: $form-border-radius;
  // background-color: $light-gray;
  // box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  box-shadow: none;
  font-family: inherit;
  font-size: $fs-18;
  font-weight: normal;
  line-height: 1.5;
  color: $dark-gray;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, [type='range']:focus,
  textarea:focus {
    border: 1px solid $gray;
    outline: none;
    background-color: lighten($gray, 50%);
    // border: none;
    // background-color: $light-gray;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  // color: lighten( $black, 50% );
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  // color: lighten( $black, 50% );
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  // color: lighten( $black, 50% );
}

input::placeholder,
textarea::placeholder {
  // color: lighten( $black, 50% );
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  // border-radius: $form-border-radius;
}

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label,
label[for='file'] {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: inline-block;
  // margin: 0 0 0 35px;
  margin: 0 0 0 8px;
  font-size: $fs-16;
  font-family: $font-secondary;
  line-height: 1.4;
  // background: $white;
  color: $black;
}
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  // color: $black;
}

.input-group {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .input-group > :first-child {
    // border-radius: $form-border-radius 0 0 $form-border-radius;
  }
  .input-group > :last-child > * {
    // border-radius: 0 $form-border-radius $form-border-radius 0;
  }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: none;
  background: #e6e6e6;
  // color: $black;
  text-align: center;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  -ms-flex-align: center;
      align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
  font-family: $font-secondary;
  font-size: $fs-20; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: none; }
.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
  font-family: $font-secondary;
  font-size: $fs-20; }

select {
  height: auto;
  width: 100%;
  margin: 0 0 1rem;
  padding: 1rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid $dark-gray;
  background-color: $white;
  font-family: inherit;
  font-weight: normal;
  font-size: $fs-18;
  line-height: 1.5;
  background-image: url('../images/arrow.svg');
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-right: 2.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
  @media screen and (min-width: 0\0) {
    select {
      // background-image: url('../images/arrow-down-red.svg');
    } }
  select:focus {
    outline: none;
    border: 2px solid $gray;
    // border: 2px solid $green;
    // background-color: $light-gray;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

