
.m-fact-box {
  position: relative;
  display: block;
  padding: 24px 24px 24px 24px;
  font-size: $fs-14;
  color: $black;
  background: $green-light-bg; //lighten($green, 54);

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;

  &:before {
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    background: $green;
  }

  &--sidebar {

    @media (min-width:$medium) {
      margin-left: 24px;
      margin-bottom: 20px;
    }
  }

  p {
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    display: block;
    margin-top: 0;
    margin-bottom: 0.3rem;
    font-family: $font-secondary;
    font-size: $fs-18;
  }

  a:link, a:active, a:visited {
    color: $black;
  }

  a:hover {
    opacity: 0.7;
  }

  blockquote {
    margin: 0;
    padding: 0;
  }

  ol, ul {
    margin: 0;
    padding: 0px 24px 0px 24px;
  }

  img {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 0;
  }

  .m-fact-box__image {
    max-width: 300px;
    margin: 16px 0 32px;
  }
}

// Modifiers
.o-article--dark-green .m-fact-box,
.m-fact-box.m-fact-box--dark-green {
  background: $dark-green-light-bg;
  &:before {
    background: $dark-green;
  }
}
.o-article--light-green .m-fact-box,
.m-fact-box.m-fact-box--light-green {
  background: $light-green-light-bg;
  &:before {
    background: $light-green;
  }
}

// Medlem
.o-article--gray .m-fact-box,
.m-fact-box.m-fact-box--gray {
  background: $white;
  &:before {
    background: $gray;
  }
}
