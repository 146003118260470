.o-calendar-teaser {
    position: relative;
    &__wrap {
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }
    &__card {
        display: inline-block;
        flex: 0 1 100%;
        width: 100%;
        height: auto;
        padding: 4px;
        margin-bottom: 1.5rem;
        color: $dark-gray;

        @media screen and (min-width: $small) {
          flex: 0 1 50%;
        }
        @media screen and (min-width: $medium) {
          flex: 0 1 25%;
        }
    }
    &__imageWrapper {
        display: block;
        height: 150px;
        overflow: hidden;
        line-height: 0;

        transition: $transition-duration;

        &:hover {
            opacity: 0.8;
            // transform: translate3d(0px, -1px, 0px);
        }

        @media screen and (max-width: $small) {
          height: 245.25px;
        }
    }
    &__image {
        max-height: 290px;
        min-height: 100%;
        width: 100%;
        @include object-fit(cover, center);
    }
    &__card-title {
        font-family: $font-secondary;
        // font-size: $fs-26;
        color: $dark-gray;
        margin: 0;
        padding: 16px 0 0;
        border-top: 3px solid;
        border-top-color: $green;
        line-height: 1.2;

        a {
            display: inline-block;
            text-decoration-line: none;
            text-decoration-color: $green;

            &:hover, &:focus {
                text-decoration-line: underline;
            }
        }
    }
    &__card-content {
        font-size: $fs-14;
        @media (min-width: $small) {
            // font-size: $fs-16;
        }

        p { margin-top: 2px; }
    }
    &__card-location {
        font-size: $fs-14;
        @media (min-width: $small) {
            // font-size: $fs-16;
        }
    }
    &__card-date {
        font-size: $fs-14;
        text-transform: capitalize;
        @media (min-width: $small) {
            // font-size: $fs-16;
        }
    }
    &__scrollright {
        position: absolute;
        margin: 0;
        top: 50%;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $green;
        z-index: 999;
        transform: rotate(-90deg) translateY(-50%);
        cursor: pointer;
        &::after {
            content: url("../images/arrow-white.svg");
            position: absolute;
            width: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__scrollleft {
        position: absolute;
        margin: 0;
        top: 50%;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $green;
        z-index: 999;
        transform: rotate(90deg) translateY(-50%);
        display: none;
        cursor: pointer;
        &::after {
            content: url("../images/arrow-white.svg");
            position: absolute;
            width: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    // Modifiers
    &--dark-green {
        & .o-calendar-teaser__scrollright,
        & .o-calendar-teaser__scrollleft {
            background: $dark-green;
        }
        & .o-calendar-teaser__card-title {
            border-top-color: $dark-green;
            a {
                text-decoration-color: $dark-green;
            }
        }
    }
    &--light-green {
        & .o-calendar-teaser__scrollright,
        & .o-calendar-teaser__scrollleft {
            background: $light-green;
        }
        & .o-calendar-teaser__card-title {
            border-top-color: $light-green;
            a {
                text-decoration-color: $light-green;
            }
        }
    }
}

.o-calendar-teaser__link{
  margin-top: 16px;
}
