.m-push {

  &--staff {
    .m-push__staff-title {
      // text-transform: uppercase;
      margin-bottom: 2px;
    }

    .m-push__staff-mail {
      // margin-bottom: 2px;
      & a {
        text-decoration: none;
        font-weight: 100 !important;
      }

      & a:hover {
        text-decoration: underline;
      }
    }

    .m-push__staff-phone {
      margin-bottom: 2px;
      & a {
        text-decoration: none;
        font-weight: 100 !important;
      }

      & a:hover {
        text-decoration: underline;
      }
    }

    .m-push__staff-company {
      margin-bottom: 2px;
    }
  }

  .m-push__wrap-link {
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  .m-push__wrap {
    padding: 8px 16px;
  }

  .m-push__imageWrapper {
    overflow: hidden;
    line-height: 0;

    > a {
      transition: $transition-duration;

      &:hover, &:focus {
        opacity: .8;
      }
    }
  }

  .m-push__image {
    &:not(.m-push__image--staff) {
      max-height: 290px;
    }
    width: 100%;
    @include object-fit(cover, center);
  }

  .m-push__iconWrapper {
    // position: relative;
    // line-height: 0;
    // min-height: 100px;

    // @media (min-width:$small) {
    //   min-height: 135px;
    // }

    position: relative;
    height: 0;
    width: 15%;
    padding: 0;
    padding-bottom: 17%;

    @media (min-width:$small){
      width: 30%;
      padding: 0;
      padding-bottom: 20%;
    }

    .m-push__icon {
      // position: absolute;
      // fill: $green;
      // left: 0;
      // bottom: 0;
      // max-height: 85%;
      // max-width: 60%;

      // @media (min-width:$small) {
      //   max-height: 70%;
      //   max-width: 60%;
      // }
      fill: $green;
      position: absolute;
      height: 95%;
      width: 100%;
      left: 3%;
      top: 0;
    }
  }

  // Color Modifier – Icon
  &--dark-green .m-push__iconWrapper .m-push__icon { fill: $dark-green; }
  &--light-green .m-push__iconWrapper .m-push__icon { fill: $light-green; }

  .m-push_contentWrapper {}

  .m-push__title {
    font-size: $fs-26;
    color: $dark-gray;
    margin: 0;
    padding: 16px 0 0;
    border-top: 3px solid;
    border-top-color: $green;
    text-decoration: none;

    a {
        display: inline-block;
        text-decoration-line: none;
        text-decoration-color: $green;

        &:hover, &:focus {
            text-decoration-line: underline;
        }
    }
  }

  // Color Modifier – Title
  &--dark-green .m-push__title {
    border-top-color: $dark-green;
    a {
      text-decoration-color: $dark-green;
    }
  }
  &--light-green .m-push__title {
    border-top-color: $light-green;
    a {
      text-decoration-color: $light-green;
    }
  }

  .m-push__content {
    font-size: $fs-14;
    margin: 0 0 16px;
    text-decoration: none;

    @media (min-width:$small) {
      font-size: $fs-16;
    }

    p {
      margin: 0;
    }

    a { font-weight: bold; }
  }

  .m-push__btn {}
}
