$showDebug: false;

div.o-accordion {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;

  @if $showDebug {
    width: 540px;
  }

  @include font-smoothing();

  &[data-mode="animate"] {
    div.o-accordion__segment {
      h3.o-accordion__title {
        svg.o-accordion__chevron {
          transition: transform 125ms ease-out;
        }
      }
    }
  }

  .o-accordion__heading {
    margin-bottom: 8px;
  }

  div.o-accordion__segment {
    margin-bottom: 10px;
    max-width: 666px;

    &:last-child {
      margin-bottom: 0;
    }

    h3.o-accordion__title {
      position: relative;
      display: block;
      width: 100%;
      background: $green;
      color: $white;
      font-size: $fs-22;
      font-weight: 400;
      line-height: 1.2;
      cursor: pointer;
      padding: 12px 100px 6px 16px;
      margin: 0;
      transition: background 150ms ease-out, padding 125ms ease-out;

      // @media (max-width: $medium) {
      //   font-size: $fs-22;
      // }

      // @media (max-width: $small) {
      //   font-size: $fs-18;
      // }

      @media (min-width: $medium) {
        &:hover {
          background: darken($green, 5);
          padding-left: 20px;
        }
      }

      svg.o-accordion__chevron {
        position: absolute;
        display: block;
        top: 13px;
        right: 16px;
        width: 26px;
        height: 26px;
        fill: $white;
        pointer-events: none;
      }

      &[aria-expanded=true] {
        svg.o-accordion__chevron {
          transform: rotate(180deg);
        }
      }
    }

    div.o-accordion__content {
      position: relative;
      display: block;
      background: $white;
      font-size: $fs-14;
      color: #777;
      // padding: 0 10px 0 10px;
      margin: 0;
      overflow: hidden;
      transition: max-height 150ms ease-out;

      &--toggleDisplay {
        display: none;
      }

      &--toggleHeight {
        max-height: 0;
      }

      > * {
        padding-left: 10px;
        padding-right: 10px;
      }

      div.o-accordion__text {
        border: 1px solid #EDEDED;
      }

      a.o-accordion__document {
        position: relative;
        display: block;
        // background: red;
        // padding: 12px 100px 6px 52px;
        padding: 10px 100px 4px 52px;
        // border-bottom: 1px solid #EDEDED;
        border: 1px solid #000;
        font-family: $font-secondary;
        font-size: $fs-24;
        line-height: 1.2;
        text-decoration: none;
        transition: background 150ms ease-out, padding 125ms ease-out;
        margin: 6px 0 0 0;

        &:last-child {
          // border: none;
        }

        &:link, &:active, &:visited{
          color: $dark-gray;
        }

        @media (min-width: $medium) {
          &:hover {
            // color: lighten($dark-gray, 50);
            // padding-left: 56px;
            // opacity: 0.7;
            background: lighten($dark-gray, 60);
          }
        }

        &:before {
          content: url('../images/file.svg');
          position: absolute;
          top: 9px;
          // top: 7px;
          left: 16px;
          width: 20px;
          height: 20px;
          // opacity: 0.4;
          opacity: 0.8;
          pointer-events: none;
        }

        &:after {
          // content: url('../images/arrow.svg');
          content: url('../images/download.svg');
          position: absolute;
          // top: 13px;
          // right: 16px;
          // top: 8px;
          // right: 10px;
          top: 8px;
          right: 14px;
          // width: 18px;
          // height: 18px;
          width: 26px;
          height: 26px;
          // transform: rotate(-90deg);
          opacity: 0.4;
          pointer-events: none;
        }

        span.o-accordion__document-name {
          position: relative;
          padding: 0;
          margin: 0;
          pointer-events: none;
        }

        span.o-accordion__document-details {
          position: relative;
          padding: 0;
          margin: 0;
          // color: lighten($dark-gray, 50);
          opacity: 0.5;
          pointer-events: none;
        }

        // svg.o-accordion__document-icon {
        //   // position: absolute;
        //   // display: block;
        //   // top: 13px;
        //   // right: 16px;
        //   position: relative;
        //   display: inline-block;
        //   width: 26px;
        //   height: 26px;
        //   fill: $white;
        //   pointer-events: none;
        // }
      }
    }
  }

  &--dark-green {
    div.o-accordion__segment {
      h3.o-accordion__title {
        background: $dark-green;
        color: $white;

        @media (min-width: $medium) {
          &:hover {
            background: darken($dark-green, 5);
          }
        }

        svg.o-accordion__chevron {
          fill: $white;
        }
      }
    }
  }
  &--light-green {
    div.o-accordion__segment {
      h3.o-accordion__title {
        background: $light-green;
        color: $white;

        @media (min-width: $medium) {
          &:hover {
            background: darken($light-green, 5);
          }
        }

        svg.o-accordion__chevron {
          fill: $white;
        }
      }
    }
  }
}
