.o-pagination {
  position: relative;
  color: $dark-gray;
  padding-bottom: 20px;

  &__list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
  }
  &__item {
    list-style: none;
    display: inline-block;
    padding: 5px;
    a {
      text-decoration: none;
      &:hover {
        color: $green;
      }
    }
    &-link {
      display: flex;
      align-items: center;
    }
    &--current {
      color: $green;
      pointer-events: none;
      font-weight: bold;
    }
    &--disabled {
      display: none;
    }
    &-next {
      position: absolute;
      right: 0;
      &:hover .o-pagination__item-chevron--arrow-next {
        transform: translate3d(2px, 0, 0) rotate(-90deg);
        transition: $transition-duration;
      }
    }
    &-prev {
      position: absolute;
      left: 0;
      &:hover .o-pagination__item-chevron--arrow-prev {
        transform: translate3d(-2px, 0, 0) rotate(90deg);
        transition: $transition-duration;
      }
    }
    &-chevron {
      width: 15px;
      height: 15px;
      fill: $dark-gray;
      transition: $transition-duration;
      &--arrow-next {
        transform: rotate(-90deg);
      }
      &--arrow-prev {
        transform: rotate(90deg);
      }
    }
  }
}
