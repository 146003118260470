/* ==========================================================================
   Typography settings
   ========================================================================== */

$font-primary: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif !default;

$font-secondary: 'Trade Gothic', 'Helvetica Neue', Helvetica, sans-serif !default;

$font-mono: 'Courier New', Courier, serif !default;

/* Font sizes */
$base-font-size: 1.8rem !default;

$fs-80: 8.0rem !default;

$fs-64: 6.4rem !default;

$fs-60: 6.0rem !default;

$fs-56: 5.6rem !default;

$fs-52: 5.2rem !default;

$fs-48: 4.8rem !default;

$fs-44: 4.4rem !default;

$fs-40: 4.0rem !default;

$fs-36: 3.6rem !default;

$fs-30: 3rem !default;

$fs-32: 3.2rem !default;

$fs-26: 2.6rem !default;

$fs-24: 2.4rem !default;

$fs-22: 2.2rem !default;

$fs-20: 2.0rem !default;

$fs-18: 1.8rem !default;

$fs-16: 1.6rem !default;

$fs-15: 1.5rem !default;

$fs-14: 1.4rem !default;

$fs-13: 1.3rem !default;

$fs-12: 1.2rem !default;

$fs-11: 1.1rem !default;

$fs-10: 1.0rem !default;

$fs-9: 0.9rem !default;

/* Line height */
$global-line-height: 1.5 !default;

$small-line-height: 1.3 !default;

$narrow-line-height: 1.2 !default;
