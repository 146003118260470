

@mixin underline($color) {
  color: inherit;
  display: inline;
  background-size: 1px .15em;
  background-repeat: repeat-x;
  background-position: 0 95%;
  background-image: linear-gradient($color, $color);
}

@mixin darkenOnHover($color) {
  background: darken($color, 5);
}

//Used for text on colored backgrounds:
@mixin font-smoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
