/* ==========================================================================
   Global typography
   ========================================================================== */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


/* ==========================================================================
   Atoms - Headings
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: $font-secondary;
  color: $dark-gray;

  &:first-child {

  }
}

h1 {

}

h2,
h3,
h4,
h5,
h6 {

}

h1 {

}

h2 {
  margin-bottom: .5rem;
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}


/* ==========================================================================
   Atoms - Paragraph
   ========================================================================== */

p {
  // font-family: $font-regular;
  color: $dark-gray;
  margin-top: .5rem;
  &:first-child {

  }
}



/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}


/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
  font-weight: inherit;
}


/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
  font-style: italic;
}

/* Add the correct display in IE 9-. */
@if $ie9andbelow {
  mark {
    // background-color: $color-mark-background;
    // color: $color-mark-color;
  }
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: inherit;
}

