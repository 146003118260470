/* ==========================================================================
   Atoms - Icons
   ========================================================================== */

$icon-size: 32px !default;

.a-icon {
  width: $icon-size;
  height: $icon-size;
  fill: transparent;
  // stroke: $color-black;
  stroke-width: 2px;

  &--twitter {
    stroke: transparent;
    // fill: $color-twitter;
  }
  &--facebook {
    stroke: transparent;
    // fill: $color-facebook;
  }
  &--linkedin {
    stroke: transparent;
    // fill: $color-linkedin;
  }
}

// Font awesome
.fas {
  color: $gray;
  margin-right: 1px !important;
  min-width: 18px;
  text-align: center;
}

