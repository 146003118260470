/* ==========================================================================
   Organisms - footer
   ========================================================================== */

.o-footer {
  position: relative;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  // background: lighten($gray, 9);

  .o-footer__title {
    margin: 0;
    color: $dark-gray;
    // text-align: center;
  }

  .o-footer__info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 $spacer;

    @media (min-width: $medium) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .o-footer__info {
    position: relative;
    font-family: $font-secondary;
    font-size: $fs-20;
    margin-bottom: 40px;
    color: $dark-gray;
    // text-align: center;
    @media (min-width: $medium) {
      margin-bottom: 10px;
    }

    &::after {
      content: url(../images/address.svg);
      position: absolute;
      width: 25px;
      height: 25px;
      top: -30px;
      left: 0;
    }

    &--address {
      &::after {
        top: -20px;
        width: 30px;
        content: url(../images/address.svg);
      }
      &-dark-green::after { content: url(../images/address-dark-green.svg); }
      &-light-green::after { content: url(../images/address-light-green.svg); }
    }
    &--mail {
      &::after {
        content: url(../images/mail.svg);
      }
      &-dark-green::after { content: url(../images/mail-dark-green.svg); }
      &-light-green::after { content: url(../images/mail-light-green.svg); }
    }
    &--phone {
      &::after {
        content: url(../images/phone.svg);
      }
      &-dark-green::after { content: url(../images/phone-dark-green.svg); }
      &-light-green::after { content: url(../images/phone-light-green.svg); }
    }

    a {
      color: $dark-gray;
      text-decoration: none;
    }
  }

  .o-footer__logo-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 $spacer;

    img {
      filter: grayscale(100%)contrast(150%);
      max-width: 200px;
      width: 100%;
    }
  }
}

.o-footer__deco {
  height: 200px;
  background-image: url('../images/deco.png');
}

.o-footer__copyright {
  margin-top: 20px;
  padding: 0 12px;

  & div {
    color: lighten($dark-gray, 40);
    font-size: $fs-12;
    margin-bottom: 8px;

    // &:first-child {
    //   border-top: 1px solid $gray;
    //   padding-top: 16px;
    // }
  }
}
