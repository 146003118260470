.toast {
  position: relative;
  width: 26px;
  height: 12px;
  margin: 0;

  &:hover { cursor: pointer; }

  .toast__bar {
    position: absolute;
    width: 26px;
    height: 4px;
    border-radius: 2px;
    background: $dark-gray;
    transition: all 0.25s ease-in-out;

    &:nth-child(1) { top: 0; }
    &:nth-child(2) { top: 8px; }
  }
}

.toast.active {
  .toast__bar {
    &:nth-child(1) { top: 50%; transform: translateY(-50%)rotate(45deg); }
    &:nth-child(2) { top: 50%; transform: translateY(-50%)rotate(-45deg); }
  }
}

// Color modifiers
.toast:hover .toast__bar { background: $green; }
.toast.toast--dark-green:hover .toast__bar { background: $dark-green; }
.toast.toast--light-green:hover .toast__bar { background: $light-green; }

