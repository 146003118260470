
// Autogenerated Yoast plugin breadcrumbs
.breadcrumbs__wrapper {
  margin-top: 12px;

  .main-breadcrumbs {
    max-width: 1024px;
    margin: 8px auto 0;
    padding: 0 $spacer;

    @media (min-width: $content-max-width) {
      margin: 0 auto 0 0;
    }

    span { font-size: 0; }

    a {
      position: relative;
      text-decoration: none;
      font-family: $font-secondary;
      font-size: $fs-18;
      line-height: 1;
      color: $dark-gray;
      margin: 0;
      padding: 2px 18px 2px 0;
      border-bottom: 3px solid transparent;
      transition: border-bottom 125ms ease-out;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0px;
        width: calc(100% - 18px);
        background-color: transparent;
      }

      &::after {
        content: url('../images/arrow.svg');
        transform: rotate(-90deg);
        position: absolute;
        width: 15px;
        top: 1px;
        right: 0px;
      }

      &:hover {
        &::before {
          height: 3px;
          background-color: $green;
          cursor: pointer;
        }
      }
    }

    .breadcrumb_last {
      position: relative;
      text-decoration: none;
      font-family: $font-secondary;
      font-size: $fs-18;
      line-height: 1;
      margin: 0;
      // padding: 2px 2px 2px 0;
      // color: $green;
      padding: 5px 8px 2px;
      background-color: $green;
      color: $white;
      @include font-smoothing();

      // &::before {
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   height: 3px;
      //   width: calc(100% - 2px);
      //   background-color: $dark-gray;
      // }
    }

  }

  &--dark-green {
    .main-breadcrumbs a:hover::before,
    .main-breadcrumbs .breadcrumb_last { background-color: $dark-green; }
  }
  &--light-green {
    .main-breadcrumbs a:hover::before,
    .main-breadcrumbs .breadcrumb_last { background-color: $light-green; }
  }
}
// !end.Autogenerated Yoast plugin breadcrumbs

div.m-breadcrumbs {
  position: relative;
  display: block;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;

  ul.m-breadcrumbs__list {
    position: relative;
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li.m-breadcrumbs__item {
      position: relative;
      display: inline-block;
      float: left;
      margin: 0;
      padding: 0;

      a.m-breadcrumbs__link {
        position: relative;
        display: block;
        text-decoration: none;
        font-family: $font-secondary;
        font-size: $fs-18;
        line-height: 1;
        color: $black;
        margin: 0;
        padding: 20px 14px 20px 0;

        &:hover,
        &--active {
          span.m-breadcrumbs__title {
            border-bottom: 3px solid $green;
          }
        }

        span.m-breadcrumbs__title {
          border-bottom: 3px solid transparent;
          transition: border-bottom 125ms ease-out;
        }

        svg.o-breadcrumbs__chevron {
          position: absolute;
          display: block;
          top: 50%;
          right: 1px;
          width: 12px;
          height: 12px;
          fill: $green;
          margin-top: -2px;
          pointer-events: none;
          transform: translate3d(0px, -50%, 0) rotate(-90deg);
        }
      }

      &:last-child {
        span.m-breadcrumbs__title {
          border-bottom: 3px solid $green;
        }
      }
    }
  }

  &--dark-green {
    ul.m-breadcrumbs__list {
      li.m-breadcrumbs__item {
        a.m-breadcrumbs__link {
          &:hover,
          &--active {
            span.m-breadcrumbs__title {
              border-bottom: 3px solid $dark-green;
            }
          }
          svg.o-breadcrumbs__chevron {
            fill: $dark-green;
          }
        }

        &:last-child {
          span.m-breadcrumbs__title {
            border-bottom: 3px solid $dark-green;
          }
        }
      }
    }
  }
  &--light-green {
    ul.m-breadcrumbs__list {
      li.m-breadcrumbs__item {
        a.m-breadcrumbs__link {
          &:hover,
          &--active {
            span.m-breadcrumbs__title {
              border-bottom: 3px solid $light-green;
            }
          }
          svg.o-breadcrumbs__chevron {
            fill: $light-green;
          }
        }

        &:last-child {
          span.m-breadcrumbs__title {
            border-bottom: 3px solid $light-green;
          }
        }
      }
    }
  }
}
