/* ==========================================================================
   Atoms - Buttons
   ========================================================================== */

.a-btn,
button,
input[type="submit"],
input[type="button"],
input[type="reset"] {

  background: $white;
  color: $dark-gray;
  font-family: $font-primary;
  font-size: $fs-14;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 6px 12px 6px;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid $green;
  border-radius: 18px;
  transition: background 150ms ease-out;
  // Overwrite edu-admin style
  height: auto;
  font-style: normal;

  &:hover {
    border-color: darken($green, 5);
    background: $green;
    color: $white;
  }

  &::after {
    content: none;
  }

  &:hover { @include darkenOnHover($green) }

  &.a-btn--dark-green {
    border-color: $dark-green;
    &:hover {
      @include darkenOnHover($dark-green);
      border-color: darken($dark-green, 5);
    }
  }
  &.a-btn--light-green {
    border-color: $light-green;
    &:hover {
      @include darkenOnHover($light-green);
      border-color: darken($light-green, 5);
    }
  }

  &.a-btn--small {
    font-family: $font-primary;
    font-size: $fs-12;
    padding: 2px 8px 1px;
    letter-spacing: 1.1px;

    &::after {
      height: 2px;
      bottom: 3px;
      width: calc(100% - 16px);
    }
  }

  &.a-btn--inverted {
    background: $green;
    color: $white;
    font-family: $font-primary;
    font-size: $fs-14;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 6px 12px 6px;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid $green;
    border-radius: 18px;
    transition: background 150ms ease-out;

    &:hover {
      @include darkenOnHover($green)
      border-color: darken($green, 5);
    }

    &::after {
      content: none;
    }
  }

  &.a-btn--block {
    display: block;
  }

  &.a-btn--secondary {
    background: $white;
    color: $green;
    border: 2px solid $green;

    &::after {
      background: $green;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }
}



// Edu-admin
.cta-btn,
.readMoreButton,
.searchButton {
  background: $white !important;
  color: $dark-gray !important;
  font-family: $font-primary;
  font-size: $fs-14;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 6px 12px 6px !important;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid $green !important;
  border-radius: 18px !important;
  transition: background 150ms ease-out;
  height: auto !important;
  font-style: normal !important;

  &:hover {
    border-color: darken($green, 5) !important;
    background: $green !important;
    color: $white !important;
  }
}

.page--dark-green {
  .cta-btn,
  .readMoreButton,
  .searchButton {
  border: 2px solid $dark-green !important;
    &:hover {
      border-color: darken($dark-green, 5) !important;
      background: $dark-green !important;
    }
  }
}
.page--light-green {
  .cta-btn,
  .readMoreButton,
  .searchButton {
  border: 2px solid $light-green !important;
    &:hover {
      border-color: darken($light-green, 5) !important;
      background: $light-green !important;
    }
  }
}
