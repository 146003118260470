/* ==========================================================================
   Generic
   ========================================================================== */

.gradientBg {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 65vh;
    background-image: $gradient-gray;
    z-index: -10;
  }

  &--invert {
    &::after {
      top: initial;
      bottom: 0;
      transform: rotate(180deg);
    }
  }
}

#content {
  // min-height: 75vh;
  margin-bottom: 20px;

  @media (min-width: $content-max-width) {
    margin-bottom: 40px;
  }
}

.content-wrapper {
  padding: 0 $spacer;

  // @media (min-width: $content-max-width) {
  //   padding: 0 0;
  // }
}

.cn-bottom {
  opacity:0.9 !important;
}

/* ==========================================================================
   Adds styling for password protected content form
   ========================================================================== */

.password-form {
  width: 100%;
  max-width: 300px;
  margin: auto;
  margin-top: 40px;
}

/* ==========================================================================
   Fixes for EduAdmin booking form layout
   ========================================================================== */

.dateInfo {
  text-align: left !important;
  margin: 0 0 2em 0!important;
  font-size: 2.0rem !important;
}
