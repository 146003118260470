/* ==========================================================================
   Main menu
   ========================================================================== */

// Separate code for the whole thing (search, btns, menu tree etc)
.m-mega-nav {
  display: none;
  padding: 12px 1%;
  // background: linear-gradient(to bottom, lighten($light-gray, 3), #fff 10%, #fff 90%, lighten($light-gray, 3));
  background: lighten($gray, 9);
  // border-top: 1px solid lighten($gray, 10);
  // border-bottom: 1px solid lighten($gray, 10);

  @media (min-width:$small) {
    padding: 12px 16px 24px;
  }

  // > .row.u-container { width: 90%; }

  // Color modifiers
  &--dark-green {
    .m-main-nav .m-main-nav__item .m-main-nav__link {
      &::after {
        background-image: -webkit-gradient(linear,left top,left bottom,from($dark-green),to($dark-green));
        background-image: -o-linear-gradient($dark-green,$dark-green);
        background-image: linear-gradient($dark-green,$dark-green);
      }
      &.current-menu-item {
        // border-bottom-color: rgba($dark-green, 1);
        background-color: $dark-green;
        color: $white;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 0px;
      }
      &.active { border-bottom-color: rgba($dark-green, 1); }
    }
  }
  &--light-green {
    .m-main-nav .m-main-nav__item .m-main-nav__link {
      &::after {
        background-image: -webkit-gradient(linear,left top,left bottom,from($light-green),to($light-green));
        background-image: -o-linear-gradient($light-green,$light-green);
        background-image: linear-gradient($light-green,$light-green);
      }
      &.current-menu-item {
        // border-bottom-color: rgba($light-green, 1);
        background-color: $light-green;
        color: $white;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 0px;
      }
      &.active { border-bottom-color: rgba($light-green, 1); }
    }
  }
  // !end.Color modifiers

  // &.active { display: block; }

  .m-mega-nav__searchWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 10px;

    @media (min-width:$small) {
      width: 60%;
      margin: 0 auto 20px;
    }

    .m-mega-nav__searchInput {
      display: inline-block;
      width: calc(100% - 70px);
      margin-bottom: 0;
    }

    .m-mega-nav__searchSubmitBtn {
      display: inline-block;
      padding: 7px 12px;
    }
  }

  .m-mega-nav__secondary-menu {
    // margin-top: 40px;

    @media (min-width:$medium) {
      margin-top: 60px;
    }

    // 3rd test
    ul {
      margin: 0 auto;
      padding: 0;

      @media (min-width:$medium) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // padding: 0 0 0 6.25%;
      }

      li {
        position: relative;
        margin-top: 0;
        display: block;

        @media (min-width:$medium) {
          flex-basis: 25%;
          max-width: 25%;
        }

        a {
          margin-left: 0;
          font-family: $font-secondary;
          font-size: $fs-26;
          margin-left: 0px;

          position: relative;
          display: inline-block;
          padding-top: 4px;
          padding-right: 0px;
          padding-bottom: 4px;
          padding-left: 0px;
          text-decoration: none;
          color: $dark-gray;
          pointer-events: all;
          z-index: 100;

          &::before { content: none; display:none; }

          transition: border-bottom-color 200ms ease-in, color 500ms ease-in;
          &::after {
           // border-bottom: 2px solid rgba($green, 0);

            content: '';
            position: absolute;
            right: 0;
            bottom: 7px;
            width: 100%;
            // height: 2px;

            background-image: -webkit-gradient(linear,left top,left bottom,from($green),to($green));
            background-image: -o-linear-gradient($green,$green);
            background-image: linear-gradient($green,$green);
            background-size: 1px 0px;
            background-repeat: repeat-x;
            background-position: 0 86%;

            transition: background-size 150ms ease-in;

            padding-bottom: 2px;
            z-index: -500;

          }
          &:hover, &:focus {
            &::after {
              background-size: 1px 2px;
            }
          }

          &.current-menu-item {
            // border-bottom-color: rgba($green, 1);
            letter-spacing: 1px;
            background-color: $green;
            color: $white;
            padding-left: 8px;
            padding-right: 8px;
            padding-bottom: 0px;
          }
          &.active { border-bottom-color: rgba($green, 1); }
        }

        &:nth-child(n+2) {
          a {
            &::before {
              content: '';
              position: absolute;
              top: 3px;
              bottom: 3px;
              left: 0;
              width: 1px;
              background-color: $black;
            }
          }
        }

        &:last-child {
          &::after {
            content: url(../images/external.svg);
            width: 14px;
            margin-left: 0px;
            display: inline-block;
            opacity: .8;
          }
        }
      }
    }

    // Regular
    // ul {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 0 auto;
    //   padding: 8px 1%;

    //   li {

    //     a {
    //       position: relative;
    //       color: $black;
    //       font-size: $fs-16;
    //       text-decoration: none;
    //       padding: 0 8px;
    //       margin: 0;

    //       &:hover { text-decoration: underline; }
    //     }

    //     &:nth-child(n+2) {
    //       a {
    //         &::before {
    //           content: '';
    //           position: absolute;
    //           top: 3px;
    //           bottom: 3px;
    //           left: 0;
    //           width: 1px;
    //           background-color: $black;
    //         }
    //       }
    //     }

    //     &:last-child {
    //       &::after {
    //         content: url(../images/external.svg);
    //         width: 8px;
    //         margin-left: -8px;
    //         display: inline-block;
    //       }
    //     }
    //   }
    // }

    // Testing colored style
    // ul {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 0 auto;
    //   padding: 0;
    //   flex-direction: column;

    //   @media (min-width:$small) {
    //     flex-direction: row;
    //     padding: 8px 1%;
    //   }

    //   li {
    //     width: 100%;

    //     @media (min-width:$small) {
    //       width: auto;
    //     }

    //     a {
    //       display: block;
    //       position: relative;
    //       background-color: $green;
    //       color: $white;
    //       font-size: $fs-16;
    //       text-decoration: none;
    //       padding: 2px 8px 3px;
    //       margin: 0 2px 4px;

    //       @media (min-width:$small) {
    //         display: inline;
    //         margin: 0 2px 0;
    //       }

    //       &:hover { text-decoration: underline; }
    //     }

    //     &:nth-child(n+2) {
    //       a {
    //         &::before {
    //           content: none;
    //         }
    //       }
    //     }

    //     &:last-child {
    //       position: relative;

    //       & a { padding-right: 26px; }

    //       &::after {
    //         position: absolute;
    //         top: 8px;
    //         right: 9px;
    //         content: url(../images/external-white.svg);
    //         width: 18px;
    //         margin: 0;
    //       }
    //     }
    //   }
    // }
  }

  .m-mega-nav__loginBtn {
    margin: 40px auto 0;
  }
}

// Menu tree
.m-main-nav__wrapper {

  /*
   * First level styles
   */
  > .m-main-nav__container > .m-main-nav {
    margin-left: 0;
    padding-left: 0;
    border-left: 0px solid transparent;
    display: block;
    opacity: 1;

    & > .m-main-nav__item > {

      & .m-main-nav {
        border-left: 8px solid rgba($white, .5);
      }

      & .m-main-nav__link {

        &--top-link {
          margin-top: 5px;
          font-family: $font-secondary;
          font-size: $fs-20;
        }
      }
    }

    & > .m-main-nav__item:not(:first-child) { margin-top: 2px; }
  }
  /* !end.first level styles */

  /*
   * Modifiers
   */
  // .m-main-nav__container > .m-main-nav {

  //   & > .m-main-nav__item {
  //     & > .m-main-nav__link:first-child {
  //     }
  //   }
  // }
  /* !end.Modifiers */

  & .m-main-nav__container {
    padding: 0;

    @media (min-width: $medium) {
      // padding: 0 0 0 25%;
    }
  }

  .m-main-nav {
    display: none;
    // margin-left: 8px;
    border-left: 8px solid rgba($white, .5);
    opacity: 0;
    transition: opacity 0.15s $transition-ease;

    &.active {
      // display: block;
      opacity: 1;
    }

    &__item {
      position: relative;
      margin-top: 0;
      @media (min-width: $small) {
        // margin-top: 3px;
      }
    }

    &__link {

      margin-left: 8px;
      position: relative;
      display: inline-block;
      // padding-top: 4px;
      padding-top: 1px;
      padding-right: 0px;
      // padding-bottom: 4px;
      padding-bottom: 1px;
      padding-left: 0px;
      font-size: $fs-14;
      font-family: $font-primary;
      text-decoration: none;
      color: $dark-gray;
      pointer-events: all;
      z-index: 100;

      @media (min-width: $small) {
        font-size: $fs-14;
      }


      transition: border-bottom-color 200ms ease-in, color 500ms ease-in;
      &::after {
       // border-bottom: 2px solid rgba($green, 0);

        content: '';
        position: absolute;
        right: 0;
        // bottom: 7px;
        bottom: 4px;
        width: 100%;
        // height: 2px;

        background-image: -webkit-gradient(linear,left top,left bottom,from($green),to($green));
        background-image: -o-linear-gradient($green,$green);
        background-image: linear-gradient($green,$green);
        background-size: 1px 0px;
        background-repeat: repeat-x;
        background-position: 0 86%;

        transition: background-size 150ms ease-in;

        padding-bottom: 2px;
        z-index: -500;

      }

      &:hover,
      &:focus {
        &::after {
          background-size: 1px 2px;
        }
      }
      &.current-menu-item {
        // border-bottom-color: rgba($green, 1);
        font-family: $font-secondary;
        letter-spacing: 1px;
        background-color: $green;
        color: $white;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 0px;
      }
      &.active { border-bottom-color: rgba($green, 1); }
    }
  }

  .m-main-nav__btn {
    display: inline-block;
    height: 38px;
    margin-left: 2px;
    padding: 12px;
    transform: rotate(0deg);
    background: transparent url('../images/arrow.svg') no-repeat 50% 50%;
    background-size: 16px 16px;
    cursor: pointer;
    border: none;
    transition: transform $transition-duration $transition-ease;

    @media (min-width: $small) {

    }

    &.active {
      transform: rotate(-180deg);
    }

    &:hover,
    &:focus {
      background-color: transparent;
      outline: none;
    }

    // Smaller icons on sublevel
    & + .m-main-nav > .m-main-nav__item .m-main-nav__btn {
      height: 34px;
      background-size: 16px 16px;
    }
  }
}

