/* ==========================================================================
   Molecules - page footer
   ========================================================================== */

.m-share {
  padding: 2%;
  margin: 0 -2%;
  width: 100%;

  @media (min-width: $small) {
    padding: 2% 0;
  }

  .m-share__list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $small) {
      flex-direction: row;
    }
  }

  .m-share__list-item {
    padding: 1% 0;
    flex-basis: 100%;
    max-width: 100%;

    @media (min-width: $small) {
      padding: 2%;
      flex-basis: 33.3334%;
      max-width: 33.3334%;
    }
  }

  .m-share__link {
    // padding: 22px $spacing*2;
    padding-left: 42px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    @media(max-width: $small) {
      padding-left: 82px;
      text-align: left;
    }

    &--facebook {
      // background-color: $color-facebook;

      &:hover, &:focus {
        // background-color: darken($color-facebook, 5%);
      }
    }

    &--twitter {
      // background-color: $color-twitter;
      padding-left: 52px;

      &:hover, &:focus {
        // background-color: darken($color-twitter, 5%);
      }

      .m-share__icon {
        top: 50%;
        transform: translate3d(0, -50%, 0) scale(0.8, 0.8);
      }
    }

    &--mail {
      .m-share__icon {
        left: 18px;
        top: 50%;
        transform: translate3d(0, -50%, 0) scale(0.8, 0.8);
      }
    }
  }

  .m-share__icon {
    // padding-right: 4px;
    position: absolute;
    // left: 22px;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
