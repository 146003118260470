/* ==========================================================================
   Organisms - header
   ========================================================================== */

.o-header {
  position: sticky;
  top: 0;
  z-index: 100;
  overflow: hidden;

  .o-header__mainWrapper {
    display: flex;
    justify-content: space-between;
  }

  .o-header__logoWrapper {
    flex-basis: 35%;
    width: 33%;
    max-width: 280px;
    margin: 3% 0 3% $spacer;

    @media (min-width:$medium) {
      flex-basis: 33%;
      margin: 1% 0 1% $spacer;
    }

    a {
      display: block;

      img {
        display: block;
      }
    }

    @media (min-width:$medium) {
      flex-basis: 15%;
      max-width: 15%;
    }
  }

  .o-header__navWrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: $spacer;

    // @media (min-width:$medium) {
    //   margin-right: $spacer;
    // }
  }

  .o-header__topMenuWrapper {
    display: none;

    &.active {
      @media (min-width:$medium) { display: block; }
    }

    .o-header__topMenu {
      display: flex;
      justify-content: flex-end;

      li {
        margin-right: 24px;

        @media (min-width:$large) {
          margin-right: 32px;
        }

        .o-header__menu-item {
          font-family: $font-secondary;
          font-weight: bold;
          font-size: $fs-18;
          text-decoration: none;
          color: $dark-gray;
          border-bottom: 3px solid transparent;
          transition: border-bottom 0.2s ease-in-out;

          @media (min-width:$large) {
            font-size: $fs-20;
          }

          &:hover { border-bottom: 3px solid $green; }

          &--dark-green:hover { border-bottom: 3px solid $dark-green; }
          &--light-green:hover { border-bottom: 3px solid $light-green; }
        }
      }
    }
  }

  .o-header__toastWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .o-header__loginBtn {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width:$medium) { display: block; }
  }

}

.o-header.active {
  max-height: 100vh;
  overflow-y: scroll;
}
