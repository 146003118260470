/* ==========================================================================
   Organisms - hero
   ========================================================================== */

.o-hero {

  min-height: 50vh;

  // No image
  .o-hero__contentWrapper {
    // padding: 20px $spacer;

    @media (min-width: $content-max-width) {
      max-width: $content-max-width;
    }
  }

  &--text {
    background-color: $green;
    opacity: 0.8;
    padding: 40px $spacer;
  }

  // Image
  &--image {
    background-size: cover;
    background-position: center;
    padding: 40px $spacer;

    .o-hero__content {
      padding: 20px;
    }
  }

  .o-hero__content.o-hero__content--w-opacity {
    position: relative;
    z-index: 0;
    display: inline-flex;
    flex-direction: column;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: $green;
      opacity: 0.8;
      width: 100%;
      height: 100%;
    }
  }

  .o-hero__title {
    font-size: $fs-48;
    line-height: $narrow-line-height;
    margin: 0;
    color: $white;
  }

  .o-hero__preamble {
    display: inline-block;
    font-size: $fs-30;
    line-height: $small-line-height;
    font-family: $font-secondary;

    & * {
      margin: 0;
    }

    p {
      color: $white;
    }

    a {
      position: relative;
      color: $dark-gray;
      text-decoration: none;
      font-weight: 700;

      @include underline($green);
    }
  }

  // Color modifiers
  &--dark-green {
    &.o-hero--text {
      background-color: $dark-green;
    }

    .o-hero__content.o-hero__content--w-opacity:after {
      background-color: $dark-green;
    }

    .o-hero__preamble a {
      @include underline($dark-green);
    }
  }
  &--light-green {
    &.o-hero--text {
      background-color: $light-green;
    }

    .o-hero__content.o-hero__content--w-opacity:after {
      background-color: $light-green;
    }

    .o-hero__preamble a {
      @include underline($light-green);
    }
  }
}
