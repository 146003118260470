/* ==========================================================================
   Utilities - Inline list
   ========================================================================== */

.u-list-inline {
  > li {
    display: inline-block;
    vertical-align: middle;
  }
}
